<template>
  <a
    class="product-spot"
    :class="{
      'product-spot--active': selected,
      'product-spot--loading': loading,
      'product-spot--interactive': interactive,
      'product-spot--card': card
    }"
    :href="product.permalink"
    :data-brand="product.brandName"
    :data-condition="product.vehicleCondition"
    :data-vehicletype="product.vehicleType"
    :data-departmentnr="product.departmentNr"
    @click="clickVehicle"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div class="product-spot__tags">
      <div v-if="product.blackWeek" class="product-tag black">
        Black Week
      </div>
      <div v-else-if="product.newYearBoost" class="product-tag black">
        NyårsBOOST
      </div>
      <div v-else-if="product.trissIncluded" class="product-tag yellow">
        100 Triss ingår
      </div>
      <div v-else-if="product.outlet" class="product-tag red">
        Outlet
      </div>
      <div v-else-if="product.temporaryDiscounted" class="product-tag red">
        Tillfälligt prissänkt
      </div>
      
      <div v-else-if="product.discount > 0" class="product-tag red">
        <FontAwesomeIcon :icon="fasTag"></FontAwesomeIcon>
        Prissänkt
      </div>
      <div v-if="product.isPremium" class="product-tag black">
        <FontAwesomeIcon :icon="falStar"></FontAwesomeIcon>
        Premium
      </div>
      <div v-if="product.campaignTagPrivate && isPrivateStore"
           class="product-tag red">
        {{ product.campaignTagPrivate }}
      </div>
      <div v-else-if="product.campaignTagCompany && !isPrivateStore"
           class="product-tag red">
        {{ product.campaignTagCompany }}
      </div>
      <div v-else-if="product.campaignTag === 'Releasing'" class="product-tag green">
        {{ product.campaignTag }}
      </div>
      <div v-else-if="product.campaignTag" class="product-tag red">
        {{ product.campaignTag }}
      </div>
    </div>
    <div class="product-spot__images-holder">
      <div class="product-spot__images__actions">
        <div
          class="product-spot__bookmark"
          :class="{ 'product-spot__bookmark--marked': isBookmarked }"
        >
          <FontAwesomeIcon
            :data-ttjs-title="
              !isLoggedIn
                ? 'Logga in för att kunna spara fordon'
                : 'Tillagd i dina sparade fordon. Under Mina sidor kan du se och ta bort sparade fordon'
            "
            @click.prevent.stop="onSaveVehicle"
            class="product-spot__bookmark__icon"
            :class="{
              'product-spot__bookmark__icon--marked': isBookmarked,
              ttjs: !isLoggedIn || isBookmarked
            }"
            :icon="isBookmarked ? fasHeart : falHeart"
          ></FontAwesomeIcon>
        </div>
        <div
          class="product-spot__bookmark"
          @click.prevent.stop="compareVehicle"
          :class="{ 'product-spot__bookmark--marked': isVehicleCompared }"
        >
          <FontAwesomeIcon
            class="product-spot__bookmark__icon"
            :class="{
              'product-spot__bookmark__icon--marked': isVehicleCompared
            }"
            :icon="isVehicleCompared ? fasBalance : falBalance"
          ></FontAwesomeIcon>
        </div>
      </div>
      <ProductImage
        v-if="
          (productImages && productImages.length === 1) ||
            !product.available ||
            windowWidth > 768
        "
        :image="productImages[0]"
        :overlay="!product.available || product.noImage"
        :overlayLabel="product.availableLabel"
      >
      </ProductImage>
      <ProductImageSlider
        v-else-if="productImages.length > 1"
        :images="productImages"
        :product="product"
      >
      </ProductImageSlider>
    </div>
    <div class="product-spot__content">
      <div class="product-spot__location">
        <i class="fas fa-map-marker-alt"></i> {{ product.locationName
        }}
      </div>
      <h2 class="product-spot__title">
        {{ product.brandModelTitle }}
      </h2>
      <div class="product-spot__description">
        {{ product.shortModelText }}
      </div>
      <div class="product-spot__info">
        <div>
          <span class="meta-tag black rounded">
            {{ product.vehicleCondition }}
          </span>
        </div>
        <div class="product-spot__info--item">
          {{ product.modelYear }}
        </div>
        <div
          class="product-spot__info--item"
          v-if="product.vehicleType !== 'Husvagn'"
        >
          <span>•</span> {{ product.mileage }} mil
        </div>
        <div class="product-spot__info--item"
             v-if="product.fuelName && product.vehicleType !== 'Husvagn'">
          <span>•</span> {{ doReplaceForDisplay(product.fuelName) }}
        </div>
      </div>
      <div class="product-spot__price" v-if="product.available">
        <div class="product-spot__price-item">
          <div class="product-spot__price-item__name">
            Pris
          </div>
          <div class="product-spot__price-item__meta">
            {{ priceMode != "noTax" ? "Inkl. moms" : "Exkl. moms" }}
          </div>
          <div
            class="price-tag"
            :class="{ 'price-tag--discount': product.discount }"
          >
            {{
              $formatPrice(
                priceMode != "noTax"
                  ? product.currentPrice
                  : product.currentPriceNoVat
              )
            }}
          </div>
        </div>
        <div class="product-spot__price-item" v-if="!displayOnlyCash">
          <div class="product-spot__price-item__name" v-if="product.flexCode == 'mgflex'">
            MG100
          </div>
          <div class="product-spot__price-item__name" v-else>
            Finansiering
          </div>
          <div class="product-spot__price-item__meta">
            Inkl. moms
          </div>
          <div
            class="price-tag"
            :class="{ 'price-tag--discount': product.discount }"
          >
            {{ $formatPriceNoSuffix(product.monthlyCost) }} kr/mån
          </div>
        </div>
        <div class="product-spot__price-item" v-if="!displayOnlyCash && product.privateLeasingPrice > 0">
          <div class="product-spot__price-item__name">
            Privatleasing
          </div>
          <div class="product-spot__price-item__meta">
            Inkl. moms
          </div>
          <div
            class="price-tag"
          >
          {{ $formatPriceNoSuffix(product.privateLeasingPrice) }} kr/mån
          </div>
        </div>
        <div class="product-spot__price-item" v-if="!displayOnlyCash && this.product.tcoMonthlyCost > 0">
          <div class="product-spot__price-item__name">
            Operationell leasing
          </div>
          <div class="product-spot__price-item__meta">
            TCO Exkl. moms
          </div>
          <div
            class="price-tag"
          >
            {{ $formatPriceNoSuffix(product.tcoMonthlyCost) }} kr/mån
          </div>
        </div>
        <div class="product-spot__price-item" v-else-if="!displayOnlyCash && this.product.blMonthlyCost > 0">
          <div class="product-spot__price-item__name">
            Business lease
          </div>
          <div class="product-spot__price-item__meta">
            Exkl. moms
          </div>
          <div
            class="price-tag"
          >
            {{ $formatPriceNoSuffix(product.blMonthlyCost) }} kr/mån
          </div>
        </div>
        <div class="product-spot__price-item" v-else-if="!displayOnlyCash && this.product.taxCode === 1">
          <div class="product-spot__price-item__name">
            Företagsleasing
          </div>
          <div class="product-spot__price-item__meta">
            Exkl. moms
          </div>
          <div
            class="price-tag"
            :class="{ 'price-tag--discount': product.discount }"
          >
            {{ $formatPriceNoSuffix(product.leasing) }} kr/mån
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import ProductImage from "./ProductImage.vue";
import ProductImageSlider from "./ProductImageSlider.vue";
import ImageResizerPresets from "../../constants/ImageResizerPresets";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHeart as fasHeart,
  faBalanceScale as fasBalance,
  faTag as fasTag
} from "@fortawesome/pro-solid-svg-icons";
import {
  faHeart as falHeart,
  faBalanceScale as falBalance,
  faLeaf as falLeaf,
  faStar as falStar
} from "@fortawesome/pro-light-svg-icons";
import { useWindowSize } from "vue-window-size";
import { EventBus } from "../../utils/event-bus";
import { replaceForDisplay } from "../../utils/replaceForDisplay";
import Axios from "../../axios";
import SaveVehicleManager from "../../models/SaveVehicleManager";
import ttjs from "../../components/ttjs";

export default {
  setup() {
    const { width } = useWindowSize();
    return {
      windowWidth: width
    };
  },
  components: {
    ProductImage,
    FontAwesomeIcon,
    ProductImageSlider
  },
  props: {
    product: Object,
    selected: Boolean,
    interactive: Boolean,
    loading: Boolean,
    card: Boolean,
    savedVehicles: Array
  },
  async mounted() {
    EventBus.$on("logged-in", () => (this.loggedIn = true));
    this.getComparedVehicles();
    EventBus.$on("comparedVehicleRemoved", this.getComparedVehicles.bind(this));
  },
  data() {
    return {
      fasHeart,
      falHeart,
      falLeaf,
      fasTag,
      isHovering: false,
      falBalance,
      fasBalance,
      falStar,
      comparedVehicles: [],
      loggedIn: window.CurrentUser !== undefined && window.CurrentUser !== null,
    };
  },
  computed: {
    image() {
      return {
        src: `${this.product.mainImage}?${ImageResizerPresets.ProductSpot}&404=${this.product.fallbackImage}`,
        alt: this.product.title
      };
    },
    productImages() {
      let productName = this.product.title;
      let fallbackImage = this.product.fallbackImage;
      return this.product.images.map(function(image, i) {
        var img = {
          src: `${image}?${ImageResizerPresets.ProductSpot}&404=${fallbackImage}`,
          alt: `${productName}${i > 0 ? "_" + i : ""}`
        };
        return img;
      });
    },
    isBookmarked() {
      return (
        this.savedVehicles && this.savedVehicles.includes(this.product.regNr)
      );
    },
    isVehicleCompared() {
      return this.comparedVehicles.includes(this.product.regNr);
    },
    isLoggedIn() {
      return this.loggedIn;
    },
    displayOnlyCash() {
      if (this.product.vehicleType == "Husbil" || this.product.vehicleType == "Husvagn") {
        return this.product.modelYear <= (new Date().getFullYear() - 10);
      }
      return false;
    },
    priceMode() {
      if (this.product.vehicleType.includes("Transport") && this.product.taxCode === 1) return "noTax";
      return "regular";
    }
  },
  methods: {
    async onSaveVehicle() {
      if (this.isLoggedIn) {
        if (this.isBookmarked) {
          await Axios.delete(`/api/MyPages/vehicles/${this.product.regNr}`);

          const tooltip = $(".ttjs-tooltip");
          tooltip.remove();
          const bookmarkTop = $(".bookmark-top");
          const bookmarkBottom = $(".bookmark-bottom");
          const tt = ttjs.tooltips.find(
            t =>
              t.reference === bookmarkTop[0] ||
              t.reference === bookmarkBottom[0]
          );
          ttjs.remove(tt);
        } else {
          const res = await Axios.post("/api/MyPages/vehicles", {
            registrationNumber: this.product.regNr
          });
        }
        SaveVehicleManager.alterVehicleList();
      }
    },
    compareVehicle() {
      EventBus.$emit("compareVehicle", this.product.regNr);
      this.$nextTick(() => {
        this.getComparedVehicles();
      });
    },
    getComparedVehicles() {
      if (window.sessionStorage)
      {
        const data = sessionStorage.getItem("comparedVehicles");
        let comparedVehicles = [];
        if (data) {
          comparedVehicles = JSON.parse(data);
        }

        this.comparedVehicles = comparedVehicles;
      }
      else {
        this.comparedVehicles = [];
      }
    },
    clickVehicle(event) {
      if (this.interactive) {
        event.preventDefault();
      }
    },
    doReplaceForDisplay(value) {
      return replaceForDisplay(value);
    }
  }
};
</script>
